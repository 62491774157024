<template>
  <CForm @submit.prevent="save" method="POST">
    <CCard no-header>
      <CCardBody>
        <h3>{{ operationName }} Guest Type</h3>
        <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>
        <CRow>
          <CCol>
            <label style="width: 95%">Name
              <a @click="toggleNameTranslations = !toggleNameTranslations">
                <CIcon name="cil-globe-alt" style="" class="text-primary"></CIcon>
              </a>
            </label>

            <div class="input-group" style="" v-show="!toggleNameTranslations" v-if="item.displayNames[0]">
              <div class="input-group-prepend">
                <span v-text="item.displayNames[0].languageCode" class="input-group-text" />
              </div>
              <input type="text" class="form-control" @id="item.displayNames[0].languageCode"
                @change="saveNameTranslation(0, $event)" v-model="item.displayNames[0].name" />
            </div>

            <div v-for="(translation, k) in item.displayNames" :key="k" v-show="toggleNameTranslations">
              <div class="input-group" style="padding-bottom: 5px">
                <div class="input-group-prepend">
                  <span v-text="translation.languageCode" class="input-group-text" />
                </div>
                <input type="text" class="form-control" @change="saveNameTranslation(k, $event)"
                  @id="translation.languageCode" v-model="item.displayNames[k].name" />
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6"></CCol>
          <CCol class="d-flex justify-content-end" style="margin-top: 20px">
            <CButton color="secondary" class="btn-lg" @click="goBack" style="margin-right: 15px">
              <CIcon name="cil-list" /> Back to the list
            </CButton>

            <CButton color="primary" type="submit">
              <CIcon name="cil-save" /> Save
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CForm>
</template>

<script>
import axios from "axios";
export default {
  name: "AddNewGuestType",
  data: () => {
    return {
      operationName: null,
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      toggleNameTranslations: false,
      languages: [],
      item: {
        id: "00000000-0000-0000-0000-000000000000",
        displayNames: [],
      },
      displayNames: [],

      //Alert
      message: null,
      alertType: "danger",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    save() {
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/GuestType`, self.item)
        .then((response) => {
          self.item.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully created guest type.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });
    },
    saveNameTranslation: function (value, event) {
      this.item.displayNames[value].name = event.target.value;
    },
    getLanguages() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ListItem/Languages?showDefaultValue=false`)
        .then(function (response) {
          self.languages = response.data;

          // Find missing translations and add them to self.item.displayNames
          response.data.forEach(function (language) {
            const found = self.item.displayNames.some(function (displayName) {
              return displayName.languageCode === language.label;
            });

            if (!found) {
              const newTranslation = { languageCode: language.label, name: "" };
              self.item.displayNames.push(newTranslation);
            }
          });
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    getGuestType(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/GuestType/${id}`)
        .then(function (response) {
          self.item = response.data;
          self.getLanguages();

          self.check();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    }
    ,
    check() {
      let self = this;
      let a = self.item.displayNames.map((a) => a.languageCode);
      let b = self.displayNames.map((b) => b.languageCode);
    },
  },

  mounted: function () {
    let self = this;

    if (self.$route.params.id != this.emptyGuid) {
      self.operationName = "Edit";
      self.getGuestType(self.$route.params.id);
    } else {
      self.operationName = "Create New";
      self.item.displayNames = self.displayNames;
      self.getLanguages();
    }
  },
};
</script>
